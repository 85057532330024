/* @import url("https://fonts.googleapis.com/css2?family=Russo+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap"); */
@font-face {
  font-family: "Russo One";
  src: url("common/fonts/RussoOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik One";
  src: url("common/fonts/RubikOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  src: url("common/fonts/OpenSans-VariableFont.ttf") format("truetype");
}

@font-face {
  font-family: "Lilita One";
  src: url("common/fonts/LilitaOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Caveat Brush";
  src: url("common/fonts/CaveatBrush-Regular.ttf") format("truetype");
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

body {
  font-family: "Russo One", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#canvas-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.touch-joystick-zone,
.touch-twobutton-zone,
.gyro-joystick-zone {
  position: fixed;
  width: 100vw;
  /* height: 100%; */
  top: 0;
  bottom: 5rem;
  /* bottom: env(safe-area-inset-bottom); */
  /* min-height: 100vh;
  min-height: -webkit-fill-available; */
  left: 0;
  z-index: 1;
  display: none;
  background-color: transparent;
  color: #fff;
  font-size: 1.2rem;
}

/* .touch-twobutton-zone.onebutton.overlay-mode{
  height: 100vh;
} */

.touch-twobutton-zone.onebutton.overlay-mode .button1 {
  width: 50vw;
  height: 50vw;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.touch-joystick-zone.active,
.touch-twobutton-zone.active {
  display: block;
}

.touch-joystick-zone .dpad,
.touch-twobutton-zone .button1 {
  position: absolute;
  width: 100vw;
  height: 100vw;
  /* background-color: rgba(255,0,0,0.1); */
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.touch-joystick-zone .dpad .circle,
.touch-twobutton-zone .button1 .circle {
  border: 4px solid #ffff;
  border-radius: 50%;
  width: 90%;
  height: 90%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.touch-joystick-zone .button1 {
  position: absolute;
  width: 28vw;
  height: 28vw;
  bottom: calc(100vw - 28vw);
  right: 5%;
  border-radius: 50%;
  border: 4px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.2);
}

.touch-joystick-zone .button2 {
  position: absolute;
  width: 50vw;
  display: none;
  height: 50vh;
  background-color: rgba(0, 255, 0, 0.1);
  bottom: 0;
  right: 0;
}

.touch-joystick-zone.two-buttons .button1 {
  height: 50vh;
}

.touch-joystick-zone.two-buttons .button2 {
  display: block;
}

.touch-twobutton-zone.onebutton .button2 {
  display: none;
}

.button-black {
  cursor: pointer;
  font-family: "Russo One", sans-serif;
  border: none;
  color: white;
  padding: 20px 40px;
  width: 100%;
  font-size: 13pt;
  margin: 5px;
  background: rgba(0, 0, 0, 1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 40px;
}

.gyro-joystick-zone.active {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gyro-joystick-zone .hidden {
  display: none;
}

.gyro-joystick-zone .btn-start {
  width: 70%;
}
