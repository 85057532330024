
.fullscreen-game-start-countdown {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    /* backdrop-filter: blur(1rem); */
}

.game-start-countdown{
    animation: 
    slam 0.5s ease-in, 
    spin-counter-clockwise 0.5s ease-in-out,
    0.1s rattle 0.55s ease-in-out;
    animation-timing-function: ease-in;

    /* color: #C41818; */
    color: white;
    text-shadow: 0.0px 10.0px 0.02px #000, 9.8px 2.1px 0.02px #000, 4.2px -9.1px 0.02px #000, -8.0px -6.0px 0.02px #000, -7.6px 6.5px 0.02px #000, 4.8px 8.8px 0.02px #000, 9.6px -2.8px 0.02px #000, -0.7px -10.0px 0.02px #000, -9.9px -1.5px 0.02px #000, -3.5px 9.4px 0.02px #000, 8.4px 5.4px 0.02px #000, 7.1px -7.0px 0.02px #000, -5.4px -8.4px 0.02px #000, -9.4px 3.5px 0.02px #000, 1.4px 9.9px 0.02px #000, 10.0px 0.8px 0.02px #000, 2.9px -9.6px 0.02px #000, -8.7px -4.8px 0.02px #000, -6.6px 7.5px 0.02px #000, 5.9px 8.0px 0.02px #000, 9.1px -4.1px 0.02px #000, -2.1px -9.8px 0.02px #000, -10.0px -0.1px 0.02px #000, -2.2px 9.8px 0.02px #000, 9.1px 4.2px 0.02px #000, 6.1px -8.0px 0.02px #000, -6.5px -7.6px 0.02px #000, -8.8px 4.7px 0.02px #000, 2.7px 9.6px 0.02px #000, 10.0px -0.6px 0.02px #000, 1.5px -9.9px 0.02px #000, -9.3px -3.6px 0.02px #000, -5.5px 8.4px 0.02px #000, 7.0px 7.2px 0.02px #000, 8.5px -5.3px 0.02px #000, -3.4px -9.4px 0.02px #000, -9.9px 1.3px 0.02px #000, -0.8px 10.0px 0.02px #000, 9.6px 2.9px 0.02px #000, 4.9px -8.7px 0.02px #000, -7.5px -6.7px 0.02px #000, -8.1px 5.9px 0.02px #000, 4.0px 9.2px 0.02px #000, 9.8px -2.0px 0.02px #000, 0.2px -10.0px 0.02px #000, -9.7px -2.3px 0.02px #000, -4.3px 9.0px 0.02px #000, 7.9px 6.1px 0.02px #000;
    display: block;
    font-family: "Russo One", sans-serif;
    font-size: 13em;
    font-weight: 900;
    position: relative;
    text-align: center;
    text-transform: uppercase;

}

@keyframes slam
{
  0%
  {
    transform: scale(10, 10);
    opacity: 0;
  }
  
  40%
  {
    opacity: 0;
  }
  
  100%
  {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes rattle
{
  0% { margin-top: 0; margin-left: 0; }
  10% { margin-top: -5px; margin-left: 0; }
  20% { margin-top: 0; margin-left: -5px; }
  30% { margin-top: 5px; margin-left: 0; }
  40% { margin-top: 0; margin-left: 5px; }
  50% { margin-top: -2px; margin-left: 0; }
  60% { margin-top: 0; margin-left: -2px; }
  70% { margin-top: 2px; margin-left: 0; }
  80% { margin-top: 0; margin-left: 2px; }
  90% { margin-top: -1px; margin-left: 0; }
  100% { margin-top: 0; margin-left: 0; }
}

@keyframes spin-counter-clockwise {
    0% {
        rotate: 360deg;
    }
    100% {
        rotate: 0deg;
    }    
}