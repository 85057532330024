.lobby-container .join-instructions-tv {
  text-align: center;
  margin-bottom: 100vh;
  /* transition: 1.5s ease-out; */
}

.lobby-container.show-lobby .join-instructions-tv {
  margin-top: -140vh;
}

.lobby-container .instruction-heading {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 39px;
  line-height: 47px;
  display: block;
  color: #ff9e9e;
  margin-top: 30vh;
  margin-bottom: 10px;
}

.lobby-container .toast-span{
  font-family: "Russo One";
  font-weight: 400;
}
