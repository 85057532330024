/* rain drop styles */
.rain-container {
  position: absolute;
  top: 0;
  bottom: -100px;
  left: 0;
  right: 0;
  z-index: -1;
  /* overflow: hidden; */
}

.rain-container.dark {
  background: linear-gradient(#131a21, #3a3793);
}

.drop {
  background-image: url("./drops/1.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 13px;
  height: 142px;
  position: absolute;
  top: -200px;
  /* -webkit-animation: fall 1.63s linear infinite;
  -moz-animation: fall 1.63s linear infinite;
  animation: fall 1.63s linear infinite; */
}

.drop.two {
  background-image: url("./drops/2.png");
}
.drop.three {
  background-image: url("./drops/3.png");
}
.drop.four {
  background-image: url("./drops/4.png");
}

/* drop animation styles */
@-webkit-keyframes fall {
  to {
    margin-top: 150vh;
  }
}
@-moz-keyframes fall {
  to {
    margin-top: 150vh;
  }
}

@keyframes fall {
  to {
    margin-top: 150vh;
  }
}
