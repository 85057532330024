.player-avatar-parent {
  height: 10rem;
  position: relative;
}

.player-avatar-parent.bottom {
  display: flex;
    flex-direction: column;
    pointer-events: none;
    justify-content: flex-end;
    height: 100vh;
}



.player-avatar-strip {
  /* display: flex; */
  /* justify-content: center; */
  position: absolute;
  z-index: 10;
  /* bottom: 0; */
  left: 0;
  right: 0;
  overflow-x: auto;
  display: flex;
}

.player-avatar-strip.top {
  top: calc(0.5rem + var(--sat));
}

.player-avatar-strip.bottom {
  bottom: calc(0.5rem + var(--sat));
  position: relative;
}

.player-avatar-strip.noabsolute {
  position: initial;
  overflow: hidden;
  flex-wrap: wrap;
}

.player-avatar-strip.centered {
  justify-content: center;
}

/* .player-avatar-strip .avatar-holder{
    overflow-y: visible;
} */

.player-avatar-strip .player-score-container.icon-only {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0rem 2.5rem;
  border-radius: 2rem;
  transform: scale(0);
  padding: 0.2rem;
  position: relative;
  overflow-y: visible;
  transition: 0.4s;
}

.player-avatar-strip.mobile .player-score-container.icon-only {
  margin: 0rem 0.5rem;
}


.player-avatar-strip .player-score-container .user-score-diff {
  text-align: center;
  vertical-align: middle;
  align-items: center;
  white-space: nowrap;
  
  font-family: 'Rubik One';
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  /* identical to box height */

  letter-spacing: -0.01em;

  background: linear-gradient(180deg, #F5FF87 39.1%, #F6C544 51.62%, #CB5515 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  position: absolute;
  top: -5.5rem;

  opacity: 0;
  transform: translateY(3rem);
  animation: 1.4s fadeInOut 0.2s forwards, 1s slideInLong 0.2s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slideInLong {
  from {
    transform: translateY(3rem);
  }
  to {
    transform: translateY(0);
  }
}


.player-avatar-strip .player-score-container .user-score-diff.No {
  font-family: 'Rubik One';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem !important;
  /* line-height: 1.5rem; */
  
  background: linear-gradient(180deg, #FF87F3 39.1%, #F244F6 51.62%, #CB2015 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.player-avatar-strip .player-score-container .user-answer {
  position: absolute;
  padding: 0.5rem 0.9rem;
  border: 0.2rem solid #000000;
  border-radius: 2rem;
  transition: 0.8s;
  /* height: fit-content; */
}

.player-avatar-strip .player-score-container .user-answer.👍 {
  background-color: #00D9BB;
}

.player-avatar-strip .player-score-container .user-answer.👎 {
  background-color: #FF6E30;
}

.player-avatar-strip .player-score-container .user-answer.no-answer {
  background-color: transparent;
  border: none;
}

.player-avatar-strip .player-score-container .user-answer.show {
  top: -3rem;
  height: fit-content;
}


.player-avatar-strip .player-score-container .user-answer.hide {
  opacity: 0;
  /* height: 0px; */
  top: 2rem;
}

.player-avatar-strip .player-score-container .score-container{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0rem 0.5rem;
  border-radius: 0.6rem;
  padding: 0.1rem;
  background-color: #ffffff;
  bottom: -1.0rem;
  overflow: visible;
  animation: 1.4s fadeInOut 0.2s forwards, 1s slideInLong 0.2s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

.player-avatar-strip .player-score-container .cartoon-dialog-box {
  background: white;
  color: black;
  box-shadow: 0 3px 0px 0px black;
  /* border-radius: 26px; */
  border: 2px solid black;
  padding: 5px 19px;
  max-width: 100%;
  width: fit-content;
  white-space: nowrap;
  user-select: none;
}

/*       ScrollBar 1        */

#scrollbar1::-webkit-scrollbar {
  width: 10vw;
  max-width: 20vw;
  height: 0.5rem;

}

#scrollbar1::-webkit-scrollbar-track {
  margin: 0px 44vw;

  height: 0.5rem;

  border-radius: 8px;
  background-color: #ffffff3c;
  /* border: 1px solid #cacaca; */
  max-width: 20rem;
  width: 20rem;
}

#scrollbar1::-webkit-scrollbar-thumb {
  border-radius: 8px;
/* border: 3px solid transparent; */
  background-clip: content-box;
  background-color: #fff;
}

.player-avatar-strip .player-score-container > span {
  color: #ffffff;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  margin: 0rem 1rem;
}

.player-avatar-strip .avatar-holder {
  background-color: rgba(255, 255, 255, 0.8);
  background-size: 23px;
  background-position: center;
  background-repeat: no-repeat;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin: 0.1rem;
  border: 0.15rem solid transparent;
}

.player-avatar-strip .empty-avatar-holder {
  /* margin: 0.1rem;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #000000;
  border-radius: 50%;
  opacity: 0.13; */
  margin: 0.1rem;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.13;
  border: none;
}


.player-avatar-strip .tick {
  /* margin: 0.1rem;
  height: 3.2rem;
  width: 3.2rem;
  background-color: #000000;
  border-radius: 50%;
  opacity: 0.13; */
  /* margin: 0.1rem; */
  height: 3.2rem;
  width: 3.2rem;
  background-color: #00000050;
  border-radius: 50%;
  /* opacity: 0.13; */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  
  position: absolute;
  animation: fadeIn 0.2s forwards;
}

.player-avatar-strip .isHost {
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  animation: fadeIn 0.2s forwards;
}

.player-avatar-strip .isHost span {
  position: absolute;
  bottom: 0.8rem;
  right: -0.8rem;
  font-size: 3.5rem;
}

.player-avatar-strip.mobile .isHost span {
  right: 1rem;
  bottom: auto;
  font-size: 1.6rem;
}

.player-avatar-strip .tick img {
  height: 14px;
  width: auto;
  /* background-image: url("../../imgs/tick.png"); */
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px center; */
}


.player-avatar-strip.mobile .avatar-holder {
  width: 2.5rem;
  height: 2.5rem;
}

.player-avatar-strip.mobile .player-score-container > span {
  font-size: 1.5rem;
  margin: 0rem 0.7rem;
}
