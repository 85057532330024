.toast-container {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  z-index: 1000;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2rem;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  padding: 2rem 4rem;
}
.toast-container.with-flag {
  padding-left: 6rem;
  background-image: url(../../imgs/flag-color.gif);
  background-repeat: no-repeat;
  background-size: 6rem;
  background-position: 0.5rem center;
}
.toast-container.bottom {
  top: auto;
  bottom: 3rem;
  transform: none;
}

.toast-container.right {
  left: auto;
  right: 3rem;
  transform: none;
}

.toast-container b {
  margin-left: 2rem;
  font-size: 3rem;
  line-height: 2rem;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .toast-container {
    font-size: 1.6rem;
    padding: 1rem 2rem;
  }
}
