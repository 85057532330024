.leaderboard {
  text-align: left;
  color: #000000;
  position: absolute;
  z-index: 4;
  // top: 3rem;
  top: calc(20px + env(safe-area-inset-top));
  left: 0rem;
  margin: 0rem 3rem;
  font-family: 'Russo One', sans-serif;
  // top: 50%;
  // transform: translateY(-50%);

  .logo-small {
    background-image: url("../../imgs/logo-blue.png");
    background-size: contain;
    display: inline-block;
    width: 12rem;
    height: 4.5rem;
    background-position: center;
    background-repeat: no-repeat;
    overflow-y: auto;
    margin-bottom: 0rem;
  }

  .avatar-holder {
    background-color: rgba(255, 255, 255, 0.8);
    background-size: 23px;
    background-position: center;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin: 0.1rem;
    border: 0.3rem solid transparent;
  }

  .leaderboard-player {
    display: flex;
    align-items: center;
    position: relative;
    margin: 1.9rem 0rem;
    padding: 0rem 6.7rem 0rem 0rem;

    .player-score,
    .player-name {
      font-size: 1.5rem;
      // https://stackoverflow.com/questions/26634201/add-stroke-around-text-on-the-outside-with-css
      /*text-shadow: -2px -2px 0 #000, 0 -2px 0 #000, 2px -2px 0 #000,
        2px 0 0 #000, 2px 2px 0 #000, 0 2px 0 #000, -2px 2px 0 #000,
        -2px 0 0 #000;*/
    }

    .player-name {
      margin-left: 1rem;
    }

    .player-score {
      font-size: 1.7rem;
      position: absolute;
      top: 1.5rem;
      left: 2rem;
      transform: rotate(12.12deg);
    }

    .player-crown {
      position: absolute;
      width: 2rem;
      top: -0.9rem;
      animation: wave 0.4s ease-in-out;
    }
  }

  .leaderboard-player.slide-in {
    animation: slide-right 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}

.leaderboard.slide-in {
  animation: slide-right 1.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

@keyframes slide-right {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes bounce-small {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  78% {
    transform: scale(0.6);
  }

  // 80% {
  //   transform: scale(0.6);
  // }

  // 88% {
  //   transform: scale(0.6);
  // }

  93% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce-big {
  0% {
    transform: rotate(12.12deg) scale(1);
  }
  60% {
    transform: rotate(12.12deg) scale(1.5);
  }
  78% {
    transform: rotate(-12deg) scale(0.2);
  }

  80% {
    transform: rotate(-6deg) scale(0.2);
  }

  88% {
    transform: rotate(7deg) scale(0.2);
  }

  93% {
    transform: rotate(12.12deg) scale(1.3);
  }
  100% {
    transform: rotate(12.12deg) scale(1);
  }
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(10deg);
  }
  78% {
    transform: rotate(-8deg);
  }

  80% {
    transform: rotate(6deg);
  }

  93% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.winner-background-gradient {
  -webkit-text-stroke: 1px black;
  color: #F6C544;
 /* text-shadow:
      3px 3px 0 #000,
    -1px -1px 0 #000,  
     1px -1px 0 #000,
     -1px 1px 0 #000,
      1px 1px 0 #000;*/
  font-family: 'Russo One';
  font-size: 2.8rem;
  font-weight: bold;
  
  .point-text {
    opacity:0;
  }
}


@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes gradient-shift {
  0% {
    background-position: 200% 200%;
  }
  50% {
    background-position: 260% 200%;
  }
  100% {
    background-position: 200% 200%;
    // background-size: -100% 300%;
  }
}

@keyframes gradient-change-1 {
  0% {
    background-position: 200% 200%;
    // background-size: -100% 300%;
    transform: translate(0%);
  }
  // 75% {
  //   transform: translate(-35%);
  // }
  // 88% {
  //   transform: translate(-35%);
  // }
  100% {
    transform: translate(-100%);
    background-position: 0% 0%;
    background-size: 200% 200%;
  }
}