.board-container {
  background: url("./img/board.png") no-repeat center;
  background-size: contain;
  width: 890px;
  height: 400px;
  position: absolute;
  top: 190px;
  left: 90px;
  font-family: "Caveat Brush";
  color: #fff;
  transform: rotate(-180deg);
}

.board-container.startscreen {
  background: url("./img/boardstart.png") no-repeat center;
  background-size: contain;
}

.board-container .word {
  position: absolute;
  top: 135px;
  left: 0;
  font-size: 90px;
  text-align: center;
  width: 95%;
}

.board-container .instruction {
  position: absolute;
  top: 233px;
  left: 0;
  font-size: 22px;
  text-align: center;
  width: 95%;
}

.keyboard-controller {
  font-family: "Caveat Brush", cursive;
  position: absolute;
  width: 100vw;
  bottom: 0;
  padding-bottom: 6.3rem;
  color: #fff;
}

.keyboard-controller .input-row {
  font-size: 5rem;
  line-height: 1;
  margin: 0px 7rem;
  border-bottom: 0.2rem solid #fff;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.keyboard-controller .timer-row {
  font-size: 3rem;
  margin: 1rem 0px;
}

.keyboard-controller .keyboard-row {
  background-color: #fff;
  border-radius: 4rem;
  display: block;
  color: #000;
  padding: 3rem;
  margin: 0px 15vw;
  font-size: 4rem;
  margin-bottom: 1rem;
}

.keyboard-controller .keyboard-row .keys {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* grid-column-gap: 2rem;
  grid-row-gap: 2rem; */
}

.keyboard-controller .keyboard-row .keys .key {
  cursor: pointer;
  display: inline-block;
  padding: 1rem;
}

.keyboard-controller .keyboard-row .keys .key:hover {
  background-color: #000;
  color: #fff;
}

.keyboard-controller .keyboard-row .enter {
  background: url("./img/checkmark.svg") no-repeat center;
  background-size: 98%;
  display: block;
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-top: 2rem;
  cursor: pointer;
}

.game-mode-container {
  background: url("./img/modecontainer.svg") no-repeat center;
  background-size: 98%;
  font-family: "Caveat Brush";
  position: absolute;
  bottom: 10px;
  padding: 15px 20px;
  padding-left: 50px;
  font-size: 13px;
  line-height: 1.2;
  left: -300px;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-mode-container > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.game-mode-container > div > span {
  font-size: 20px;
}
