.player-avatar-strip {
  display: flex;
  /* justify-content: center; */
  position: absolute;
  z-index: 10;
  top: calc(0.5rem + var(--sat));
  /* bottom: 0; */
  left: 0;
  right: 0;
  overflow-x: auto;
}

.player-avatar-strip .player-score-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0rem 0.5rem;
  border-radius: 2rem;
  padding: 0.2rem;
}

.player-avatar-strip .player-score-container > span {
  color: #ffffff;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  margin: 0rem 1rem;
}

.player-avatar-strip .avatar-holder {
  background-color: rgba(255, 255, 255, 0.8);
  background-size: 23px;
  background-position: center;
  background-repeat: no-repeat;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin: 0.1rem;
  border: 0.15rem solid transparent;
}

.player-avatar-strip.mobile .avatar-holder {
  width: 2.5rem;
  height: 2.5rem;
}

.player-avatar-strip.mobile .player-score-container > span {
  font-size: 1.5rem;
  margin: 0rem 0.7rem;
}
