.inviteCodeModal input {
  width: 100%;
  height: 70px;
  border: 1px solid #333;
  background-color: #000;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 20px;
  color: #fff;
  outline: none;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.inviteCodeModal .error {
  display: block;
  color: red;
  margin: 20px 0px;
}

.inviteCodeModal button {
  padding: 10px 20px;
}
