.rate-game-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    height: 150px;
    width: 100%;
    padding: 10px;
}

.rate-game-modal {
    background-color: #FFA6F1;
    border: 2px solid #FFF27A;
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px 8px 10px;
}

.rate-game-modal-span {
    background-color: #FFF27A;
    font-size: 10px;
    border-radius: 12px;
    color: black;
    padding: 4px 12px;
    position: absolute;
    top: 0;
}

.rate-game-modal h3 {
    padding: 0;
    margin: 0;
    margin-top: 20px;
    color: black;
    font-size: 16px;
    font-weight: normal;
}

.rate-game-modal p {
    padding: 0;
    margin: 0;
    color: black;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 1000;
}

.rate-game-modal .rating {
    display: flex;
    width: 100%;
    height: 35px;
    margin-right: 25px;
    margin-top: 14px;
    margin-bottom: 10px;
}