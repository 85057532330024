/* .joinRoomModal.fullscreen #overlay{
  background-color: #2811a0;
} */

.joinRoomModal.fullscreen #popup{
  background-color: #060606;
  border-radius: 0;
}

.joinRoomModal .popupcontent > span {
  font-size: 1.3rem;
  margin-bottom: 40px;
  display: block;
  text-align: center;
  padding: 0px 6rem;
  max-width: 50rem;
}

.joinRoomModal .logo-small {
  background-image: url("../../imgs/logo.png");
  position: absolute;
  background-size: contain;
  display: inline-block;
  width: 10rem;
  height: 4.5rem;
  top: 2rem;
  left: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: auto;
  margin-bottom: 0rem;
}

.joinRoomModal .popupcontent > span.error {
  color: #bf0000;
}

.joinRoomModal .modal-container .fullscreen .popupcontent {
  margin-top: calc(50vh - 200px);
}

.joinRoomModal.fullscreen #overlay.show {
  opacity: 1 !important;
  border-radius: 0;
}

.joinRoomModal .react-code-input input:first-child {
  border-color: #ffb0cc;
  color: #ffb0cc;
}

.joinRoomModal .react-code-input input:nth-child(2) {
  border-color: #fff27a;
  color: #fff27a;
}

.joinRoomModal .react-code-input input:nth-child(3) {
  border-color: #6bdcff;
  color: #6bdcff;
}

.joinRoomModal .react-code-input input:nth-child(4) {
  border-color: #ff7f56;
  color: #ff7f56;
}

.joinRoomModal.fullscreen .popupcontent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1rem;
}
