.game-pickthelargest {
  .view-container {
    /* position: absolute;
    top: 100px; */
    margin-top: 50px;
  }

  .grid {
    display: inline-block;
    border: 4px solid #ea6bff;
  }
  .row {
    display: flex;
  }

  .cell {
    display: inline-block;
    height: 100px;
    width: 100px;
    transition: all 0.2s linear;
    margin: -2px; /* hack to avoid disconnects between cells */
  }

  .player-cursor-container {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s linear;
  }

  .player-scores {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }

  .player-cursor-container .round-score {
    position: absolute;
    top: -43px;
    right: -20px;
    color: #fff;
    font-size: 45px;
  }

  .avatar-holder {
    background-color: rgba(255, 255, 255, 0.8);
    background-size: 23px;
    background-position: center;
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 5px;
    border: 3px solid transparent;
    box-shadow: #00000082 5px 5px 20px;
  }
  .player-cursor-container.disabled .avatar-holder {
    filter: brightness(0.8);
    box-shadow: none;
  }

  .round-text {
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
    background: rgba(0, 0, 0, 0.5);
    font-size: 3rem;
    top: 50%;
    transform: translateY(-50%);
    padding: 20px 0px;
  }

  .round-text .dots .dot {
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
    border-radius: 50%;
    display: inline-block;
    margin: 10px;
  }

  .round-text .dots .dot.done {
    background: #fff;
  }
}
