.controls-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column-reverse;
  padding-bottom: 100px;
  align-items: center;
}

.controls-container > button {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  font-size: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffffa1;
  text-transform: uppercase;
  border-width: 5px;
}

.player-rocket-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 1.3rem;
}

.player-rocket-container .winner-container {
  font-family: "Lilita One", cursive;
  opacity: 0;
  color: #ffffff;
  font-size: 7rem;
  letter-spacing: 0.5rem;
  /* text-transform: uppercase; */
  position: absolute;
  margin-top: -10rem;
  transition: all 1.5s ease-out;
}

.player-rocket-container .winner-container.visible {
  opacity: 1;
  margin-top: 2rem;
}

.launch-pad-platform {
  width: 11rem;
  display: block;
  height: 2rem;
  background: #fff;
  margin-top: 4rem;
}

.launch-pad {
  position: relative;
  width: 7rem;
  height: 10rem;
  display: inline-block;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(196, 196, 196, 0) 66.42%
  );
  z-index: -1;
}

.launch-pad .launch-bar {
  background-color: #5bc0de;
  width: 70% !important;
  position: absolute;
  bottom: 0;
  transition: height 0.5s;
  left: 15%;
  box-shadow: inset 4px 4px 8px rgb(255 255 255 / 25%);
  border-radius: 5px;
}

.rockets-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column-reverse;
  align-items: center;
  transition: bottom 4s;
}

.rockets-container.won {
  bottom: -50vh;
}

.rockets-container .rockets {
  display: flex;
}

.rocket-ship {
  position: relative;
  transform: translate(0px, 3rem);
  transition: transform 2s ease-in;
}
.rocket-ship.launched {
  transform: translateY(-100vh);
}

.rocket-ship .avatar-holder {
  width: 5rem;
  height: 5rem;
  background-color: #ffffff;
  border: 0.3rem solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2.5rem;
  left: 5.2rem;
}

.rocket-countdown-container {
  position: absolute;
  left: 50%;
  top: 40%;
  color: #fff;
  font-size: 9rem;
  font-family: "Lilita One", cursive;
  opacity: 0.5;
  transform: translate(-50%, -50%);
}

.rocket-ship .flame {
  width: 1.5rem;
  height: 20rem;
  position: absolute;
  top: 13rem;
  z-index: -1;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 50%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  mask-image: -webkit-gradient(
    linear,
    left 50%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  background: linear-gradient(
      rgba(255, 0, 0, 1) 0%,
      rgba(255, 154, 0, 1) 10%,
      rgba(208, 222, 33, 1) 20%,
      rgba(79, 220, 74, 1) 30%,
      rgba(63, 218, 216, 1) 40%,
      rgba(47, 201, 226, 1) 50%,
      rgba(28, 127, 238, 1) 60%,
      rgba(95, 21, 242, 1) 70%,
      rgba(186, 12, 248, 1) 80%,
      rgba(251, 7, 217, 1) 90%,
      rgba(255, 0, 0, 1) 100%
    )
    0 0/100% 200%;
  animation: bgslide 2s linear infinite;
  opacity: 0;
}

.rocket-ship .flame.left {
  left: 3rem;
}

.rocket-ship.launched .flame {
  animation: bgslide 2s linear infinite, fadeIn 2s;
  opacity: 1;
}

.rocket-ship .flame.right {
  left: 10.5rem;
}

@keyframes bgslide {
  to {
    background-position: 0 -200%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.background-game-container .star {
  background: url("./img/star.png") no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  position: absolute;
}

.background-game-container .star.topleft {
  top: 10rem;
  left: 10rem;
}

.background-game-container .star.topright {
  top: 13rem;
  right: 5rem;
  transform: scale(1.3);
}

.background-game-container .star.bottomleft {
  bottom: 13rem;
  left: 5rem;
  transform: scale(1.1);
}

.background-game-container .star.bottomright {
  bottom: 9rem;
  right: 15rem;
  transform: scale(1.4);
}

.touch-twobutton-zone.rocketfuel .button1 {
  color: #000000;
  text-transform: uppercase;
}

.touch-twobutton-zone.rocketfuel .button1 .circle {
  border-color: #000000;
  background-image: url("./img/fuelicon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: 70%;
  background-size: 30px;
}

.controller-progressbar {
  position: relative;
  width: 2rem;
  height: 15rem;
  display: inline-block;
  background: #fff;
  z-index: -1;
}

.controller-progressbar .bar {
  background-color: #5bc0de;
  width: 70% !important;
  position: absolute;
  bottom: 0.3rem;
  /* transition: height 0.5s; */
  left: 15%;
  box-shadow: inset 4px 4px 8px rgb(255 255 255 / 25%);
  border-radius: 5px;
}

.controller-progressbar .finish-line {
  position: absolute;
  background: url("./img/finishline.png") no-repeat;
  background-size: cover;
  width: 6rem;
  height: 1rem;
  top: 3px;
  transform: translateX(-33%);
}
