.platform {
  position: fixed;
  top: 50%;
  /* left: 0; */
  right: 0;
  z-index: 3; /* above touch joystick */
  transform: translate(-50%, -50%);
  /* background: url("./img/platform.png") no-repeat; */
  background-size: contain;
  width: 90vw;
  left: 50%;
  /* height: 70vh; */
  background-position: 50% 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.back-dark-gradient {
  background: #5F33FF;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.platform .avatar-container {
  width: 100%;
  height: 100%;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
}

.full-avatar-container {
  width: 80px;
  height: 100px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;
}

.player-shadow {
  display: block;
  position: absolute;
  bottom: -45%;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.platform .avatar-photo {
  width: 20rem;
  height: 20rem;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 50%;
  border: 4px solid red;
      background-color: rgba(0,0,0, 0.4);
    backdrop-filter: blur(20px);
}

.platform .avatar-photo.no-photo {
  background-size: 25%;
  background-position: center;
}

.platform .color-tray {
  /* margin-top: 20px; */
  z-index: 3;
  display: block;
}
.platform .color-tray .color {
  border: 3px solid #fff;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  border-radius: 50%;
  margin: 2px;
  vertical-align: middle;
}

.platform .input-name {
  background: #000;
  border: 4px solid #fff;
  border-radius: 3rem;
  padding: 20px 0px;
  font-size: 1.5rem;
  margin: 20px;
  text-align: center;
  font-family: inherit;
  color: #fff;
  text-shadow: 2px 2px #000, -2px 2px #000, 2px -2px #000, -2px -2px #000;
  margin-top: -30px;
  z-index: 1;
}

.platform .input-name:focus {
  outline: none;
}

.platform .color-tray .color.active {
  width: 3.5rem;
  height: 3.5rem;
  border-color: #000;
  border-width: 4px;
}

.platform .button-black {
  margin-top: 16rem;
}

.button-crop {
  background-color: white;
  color: black;
  width: auto;
  margin-top: 20px;
}

.platform .refresh-button {
  position: absolute;
  right: 15px;
  color: #000;
  cursor: pointer;
  background: #fff;
  padding: 10px;
  z-index: 1;
  border-radius: 50%;
  line-height: 0;
  top: 15px;
}