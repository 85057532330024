//I prefer span elements over pseudo too cause It's easier to use on libraries like React or Tailwind

$base-color: #333954;

@mixin baseStyles {
  position: relative;
  display: flex;
  align-items: center;
}

@mixin confettiBase {
  position: absolute;
  width: 1rem;
  height: 1rem;
  z-index: 0;
  will-change: transform;
}

//Base Styles

  .subContainer {
    width: fit-content;
    height: fit-content;
    @include baseStyles;
    flex-direction: column;
    justify-content: center;
    font-size: 5rem;
    // .background {
    //   width: fit-content;
    //   height: fit-content;
    //   border-radius: 100%;
    //   position: absolute;
    //   background: transparent;
    // //   z-index: -1;
    // }

    .square {
      @include confettiBase;
    }
    .circle {
      @include confettiBase;
      border-radius: 100%;
    }
    .triangle {
      position: absolute;
      z-index: 0;
      border-style: solid;
      border-width: 0 1rem 2rem 1rem;
    }
  }

.startConfetti {
//   .background {
//     animation: pulse 0.8s linear 1;
//   }
  .square {
    animation: square 0.9s linear 1 forwards;
  }
  .circle {
    animation: circle 0.9s linear 1 forwards;
  }
  .triangle {
    animation: triangle 0.8s linear 1 forwards;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 papayawhip;
  }
  100% {
    box-shadow: 0 0 0 2rem transparent;
  }
}

@keyframes square {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
    background: yellow;
  }
  100% {
    transform: translate(1100%, -900%) rotate(180deg);
    background: transparent;
  }
}

@keyframes circle {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
    background: #11a683;
  }
  100% {
    transform: translate(-700%, -700%);
    background: transparent;
  }
}

@keyframes triangle {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
    border-color: transparent transparent crimson transparent;
  }
  100% {
    transform: translate(0%, -1000%) rotate(180deg);
    border-color: transparent transparent transparent transparent;
  }
}