.soundContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 1;
}

.muteButton {
  background: url("../../imgs/soundoff.png") no-repeat center;
  min-width: 4.8rem;
  min-height: 1.8rem;
  border-radius: 11px;
  cursor: pointer;
  background-color: transparent;
}

.muteButton:hover {
  background-color: #000 !important;
}

.muteButtonContainer {
  background-color: rgba(52, 9, 236, 255);
  height: 100%;
  display: flex;
  align-self: center;
  background-size: 50%;
  flex: 1 1 1;
}

.round {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.7);
}

.on {
  background-image: url("../../imgs/soundon.png");
}

.castMode {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  bottom: 3rem;
  left: 3rem;
  width: 4.5rem;
  height: 4.5rem;
  z-index: 5;
}
