
$bg : #01191d;
$green : #18F4A3;
$bluegreen : #00ffea;
$bluewhite: #dafffe;

.fire-container {
  bottom: -1rem;
  z-index: -4;
  padding: 0rem 4rem;
  position: absolute;
  width: 100%;
}

.fires {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.fire {
    // top: 48%;
    // z-index: 10;
    // position: absolute;
      // left: 50%;
      // margin-left: -10px;
      width: 20px;
      height: 20px;
      border-radius: 55% 50% 80% 0 / 80% 50% 55% 0;
      background-color: $bluegreen;
      transform: rotate(135deg);
      margin-top: 20px;
    animation: flicker infinite 1s alternate ease-in-out;
  }
  
  .fire:after {
    content: "";

    /* copied from browser dev tools */
    top: 8px;
    left: -28px;
    right: -17px;
    margin-left: auto;
    margin-right: auto;

    position: absolute;
      width: 15px;
      height: 15px;
      border-radius: 55% 50% 80% 0 / 80% 50% 55% 0;
      background-color: $bluewhite;
      
  }
  
  @keyframes flicker {
    0% {
   transform: rotate(135deg) scale(0.8);
      box-shadow: 
      0 0 17px 10px rgba(165, 238, 224, 0.5);
    }
    
    25% {
      
      box-shadow: 
      0 0 17px 5px rgba(165, 238, 224, 0.5);
    }
    
    50% {
      box-shadow: 
      0 0 17px 7px rgba(165, 238, 224, 0.5);
    }
    
    75% {
      
      box-shadow: 
      0 0 17px 5px rgba(165, 238, 224, 0.5);
    }
    
    100% {
      
      box-shadow: 
      0 0 17px 10px rgba(165, 238, 224, 0.5);
    }
  }
  