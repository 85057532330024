.back-gradient {
  background: linear-gradient(
    168.47deg,
    #fbff35 -7.63%,
    #ffa6f1 12.27%,
    #3905f5 104.74%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.dark-bg {
  background: #1b1b31;
}

.message-container {
  position: absolute;
  bottom: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  color: #fff;
  width: 80vw;
  margin-left: -40vw;
  margin-top: -40vw;
  z-index: 2;
}

.message-container h1 {
  font-size: 14pt;
}

.wait-screen-container {
  padding-top: env(safe-area-inset-top);
  background-color: #3905f5;
  /* margin: 10px; */
  /* padding: 10px; */
  color: #fff;
  text-align: left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: auto;
}

.wait-screen-container.gradient {
  background: linear-gradient(
    299.28deg,
    #000000 8.06%,
    rgba(0, 0, 0, 0) 69.42%
  );
  background-color: #3905f5;
}

.wait-screen-container.is-host {
  padding: 10px 20px;
  color: #fff;
  text-align: left;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: auto;
  margin: 0;
  background: radial-gradient(
    78.52% 97.27% at 50% 97.27%,
    #3a3793 0%,
    #131a21 100%
  );
}

.wait-screen-container.is-host .rain-container {
  bottom: 0;
  background: transparent !important;
}

.wait-screen-container .logo-small {
  background-image: url("/src/common/imgs/logo.png");
  background-size: contain;
  display: inline-block;
  width: 9rem;
  height: 4.5rem;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: auto;
}

.wait-screen-container .cast-instructions {
  margin-top: 100px;
}
/* .wait-screen-container .room-code {
  float: right;
  color: #fff;
  border: 2px solid #ff9680;
  border-radius: 20px;
  padding: 10px 25px;
} */

.wait-screen-container .instruction-heading {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 3.5rem;
  display: block;
  color: #ff9e9e;
  margin-top: 30vh;
  margin-bottom: 10px;

  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.wait-screen-container.is-host .instruction-heading {
  margin-top: 3rem;
}

.wait-screen-container .instruction-heading.center {
  text-align: center;
}

.wait-screen-container .instruction-title {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding-left: 40px;

  background-size: 26px;
  background-position: left center;
  background-repeat: no-repeat;
}

.wait-screen-container .instruction-title.laptop {
  background-image: url("/src/common/imgs/cast-laptop.png");
}

.wait-screen-container .instruction-title.airplay {
  background-image: url("/src/common/imgs/cast-airplay.png");
}

.wait-screen-container .instruction-text {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 60px;
}

.wait-screen-container .icon-screenmirror {
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 26px;
  height: 20px;
  background-image: url("/src/common/imgs/screen-mirror.png");
  display: inline-block;
  margin: 0px 8px;
}

.wait-screen-container .instruction-text:last-child {
  padding-bottom: 100px;
}

.wait-screen-container .instruction-text.highlighted {
  color: #6bdcff;
}
.wait-screen-container .instruction-text li {
  margin-bottom: 20px;
}

.wait-screen-container .join-instructions-tv {
  text-align: center;
}
/* .wait-screen-container .qr-code {
  background-color: #fff;
  padding: 10px;
  display: inline-block;
} */

.wait-screen-container .instruction-subtitle {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  /* margin: 40px; */
}

.wait-screen-container .splash-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.wait-screen-container .splash-screen .logo-big {
  background-image: url("../../common/imgs/logo.png");
  background-size: contain;
  display: inline-block;
  width: 40vw;
  height: 15vh;
  background-position: center;
  background-repeat: no-repeat;
  overflow-y: auto;
  margin-bottom: 0rem;
}

.wait-screen-container .splash-screen .msg-container {
  display: flex;
  align-items: center;
  /* margin-top: 1rem; */
}

.wait-screen-container .splash-screen .flag-gif {
  width: 8rem;
}

.wait-screen-container .splash-screen p {
  color: #ffffff;
  font-size: 2rem;
}

.rain-container.waitscreen-gradient {
  background: radial-gradient(
    78.52% 97.27% at 50% 97.27%,
    #3a3793 0%,
    #131a21 100%
  );
}

.wait-screen-container .waiting-for-players-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 85%;
  width: 100vw;
}
.wait-screen-container .waiting-for-players-container-hint {
  position: fixed;
  bottom: 4rem;
  right: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 1.55rem;
  padding: 0px 2rem;
}

.wait-screen-container
  .waiting-for-players-container-hint
  .instruction-heading {
  color: #ffffff;
  white-space: nowrap;
  font-size: 1.7rem;
}

.scrolling-carousal {
  overflow: hidden;
  width: 100vw;
  margin-top: 9rem;
  /* margin: 50px auto; */
  /* transition: 0.5s opacity; */
  /* transform: translateX(100vw); */
  /* opacity: 0; */
  /* visibility: hidden; */
  /* background: #fff; */
}

.scrolling-carousal.visible {
  /* transform: translateX(0); */
  /* opacity: 1; */
}

.scrolling-carousal .scrolling-carousal-inner {
  width: 700vw;
  transition: 0.5s transform;
  transform: translateX(100vw);
  /* height: 100px; */
  /* font-size: 0px; */
}

.scrolling-carousal.visible .scrolling-carousal-inner {
  transform: translateX(0);
}

.scrolling-carousal .scrolling-carousal-inner > .scrolling-carousal-item {
  display: inline-block;
  width: auto;
  float: none;
  padding: 0 0.6rem;
}

.scrolling-carousal .scrolling-carousal-inner > .scrolling-carousal-item img {
  height: 44rem;
  width: auto;
}
