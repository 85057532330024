.controller-hud-container {
  position: fixed;
  width: 100vw;
  top: 0;
  bottom: 5rem;
  left: 0;
  background-color: rgba(255, 0, 255, 0.1);
  color: #fff;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  padding-top: calc(30px + env(safe-area-inset-top));
}

.controller-hud-container .breadcrumbs {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}

.controller-hud-container .breadcrumbs > span {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  width: fit-content;
  font-family: 'Russo One';
}

.controller-hud-container .breadcrumbs .roomcode {
  background: #0a05f5cc;
}

.controller-hud-container .breadcrumbs .roomcode span:first-child {
  border-color: #ffb0cc;
  color: #ffb0cc;
}

.controller-hud-container .breadcrumbs .roomcode span:nth-child(2) {
  border-color: #fff27a;
  color: #fff27a;
}

.controller-hud-container .breadcrumbs .roomcode span:nth-child(3) {
  border-color: #6bdcff;
  color: #6bdcff;
}

.controller-hud-container .breadcrumbs .roomcode span:nth-child(4) {
  border-color: #ff7f56;
  color: #ff7f56;
}

.controller-hud-container .hud-avatar {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.controller-hud-container .hud-avatar .avatar-text {
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: 2.3rem;
  -webkit-text-stroke: 2px black;
  line-height: 5rem;
}

.controller-hud-container .hud-avatar .avatar {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
}

.controller-hud-container .controller-hud-center {
  position: absolute;
  width: 100%;
  height: calc(100vh - 100vw - 10vh);
  display: flex;
  justify-content: center;
  align-items: center;
}

.controller-hud-container .circular-progress {
  width: 5rem;
  height: 5rem;
}

.controller-hud-container .circular-progress svg {
  width: 100%;
  height: 100%;
}
