.game-title {
    color: #000000;
    /* border: 4px solid #FFFD66; */
    text-shadow: 
    -0.2rem 0.2rem 2px #FFFD66,
    0.2rem 0.2rem 2px #FFFD66,
    0.2rem -0.2rem 0 #FFFD66,
    -0.2rem -0.2rem 0 #FFFD66,
    0 0.56rem 0 #FF66DD;
    font-size: 6.5rem;

}


.banana-container {
    color: #000;
    opacity: 1;
    background-color: #FFFD66;
    border: 0.3rem solid #FFA531;
    border-bottom: 0.6rem solid #FF7F56;
    border-radius: 2rem;
  }

  .banana-text {
    color: #000000;
    /* border: 4px solid #FFFD66; */
    text-shadow: 
    -0.2rem 0.2rem 2px #FFFD66,
    0.2rem 0.2rem 2px #FFFD66,
    0.2rem -0.2rem 0 #FFFD66,
    -0.2rem -0.2rem 0 #FFFD66,
    0 0.56rem 0 #FF66DD;
  }

  .banana-container.btn {
    border-bottom: 0.8rem solid #FF7F56;
    border-radius: 2.4rem;
    transition: 0.1s ease-in;  
  }
  
  .banana-container.btn:active {
    border-bottom: 0.6rem solid #FF7F56;
    background-color: #e8e542;
    transition: 0.05s ease-out;  
    transform: scale(0.97);
  }