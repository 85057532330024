body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}
#canvas-container {
  /* background-image: linear-gradient(#131A21, #3A3793); */
  display: flex;
  /* align-content: center; */
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

html {
  font-size: 1.5vh;
}

p {
  font-size: 1rem;
}

h1 {
  font-size: 4rem;
}

/* @media (min-width: 2200px) { 
  body{
    transform: scale(2);
  }
} */

.font-load-hack {
  position: absolute;
  top: -100vh;
  left: -100vw;
}

.font-load-hack span {
  font-family: "Lilita One";
}
