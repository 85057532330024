/* Smoke container */

.circular-smoke {
    position: absolute;
    z-index: -1;
    width: 1px;
    height: 160px;
    left: 50%;
    /* bottom: 30px; */
    bottom: 0;
}

/* Smoke balls */

.circular-smoke span {
    display: block;
    position: absolute;
    bottom: -35px;
    left: 50%;
    margin-left: -20px;
    height: 0px;
    width: 0px;
    border: 35px solid #ecececd5;
    border-radius: 35px;
    left: -14px;
    opacity: 0;
    transform: scale(0.2);
}

/* Smoke animation */

@keyframes smokeL {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, -5px) }
	100% { opacity: 0; transform: scale(1) translate(-20px, -130px) }
}

@keyframes smokeR {
	0%   { transform: scale(0.2) translate(0, 0) }
	10%  { opacity: 1; transform: scale(0.2) translate(0, -5px) }
	100% { opacity: 0; transform: scale(1) translate(20px, -130px) }
}

.circular-smoke .s0 { animation: smokeL 10s 0s infinite }
.circular-smoke .s1 { animation: smokeR 10s 1s infinite }
.circular-smoke .s2 { animation: smokeL 10s 2s infinite }
.circular-smoke .s3 { animation: smokeR 10s 3s infinite }
.circular-smoke .s4 { animation: smokeL 10s 4s infinite }
.circular-smoke .s5 { animation: smokeR 10s 5s infinite }
.circular-smoke .s6 { animation: smokeL 10s 6s infinite }
.circular-smoke .s7 { animation: smokeR 10s 7s infinite }
.circular-smoke .s8 { animation: smokeL 10s 8s infinite }
.circular-smoke .s9 { animation: smokeR 10s 9s infinite }