body {
  margin: 0;
}
.ui-center {
  text-align: center;
}

.carousel-container {
  /* position: relative; */
  height: 300px;
  /* max-width: 960px; */
  width: 100%;
  margin: 0 auto;
  /* overflow-x: hidden;
  overflow-y: visible; */
  overflow: visible;
  touch-action: pan-y;
}

.carousel-track {
  display: flex;
  height: 100%;
}

.carousel-card {
  flex: 0 0 130px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  color: white;
  -webkit-tap-highlight-color: transparent;
}

.image-container {
  max-width: 8em;
  /* max-height: 8em; */
  width: 4em;
  height: 4em;
  background-size: cover;
  background-repeat: no-repeat;
  /* margin-bottom: 2em; */
  /* border-radius: 100%; */
  /* padding: 1em; */
  /* -webkit-box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.3);
-moz-box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.3);
box-shadow: inset 0px 0px 17px 0px rgba(0,0,0,0.3); */
  
}

.carousel-card-inner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 130px;
  height: 180px;
  /* border-radius: 2px; */
  color: rgba(0,0,0,.5);
  transition: inherit;
  /* -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.3); */
  border: 5px solid black;
  border-radius: 0.8em;
}

.carousel-title {
  margin-top: 20px;
  font-size: 1.1em;
  text-transform: uppercase;
}

.carousel-text {
  padding: 1em;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: left;
  font-family: sans-serif;
}



/* Animation for flying card */
@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.14);
  }
  70% { transform: scale(.8); }
  /* 80% { transform: scale(1.05); }
  92% { transform: scale(0.9); } */
  100% { transform: scale(1); }

}

.dropzone-card {
  /* opacity: 0;
  transform: translateY(2rem); */
  animation: slide-in-rotate 0.4s forwards ease-in-out;
}

/* Animation for dropzone card */
@keyframes slide-in-rotate {
  0% {
    opacity: 0;
    transform: translateY(6rem);
  }
  50% {
    opacity: 1;
    rotate: 0deg;
  }
  70% {
    transform: translateY(0);
  }
  100% {
    rotate: 11deg;
  }
}

/* Prevent elements from being selected  
https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
*/
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
