.confetti-canvas {
    display: block;
    vertical-align: bottom;
}

/* ---- tsparticles container ---- */
#tsparticles {
position: fixed;
width: 100%;
height: 100%;
z-index: 9999;
}