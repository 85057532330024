.finger-pointer {
    position: absolute;
}

/* .finger-pointer img {
    box-shadow: 0 3px 0px 0px black;
    border: 2px solid black;
} */

@keyframes oscillate-diagonal-tr-br {
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(5px, 5px);
    }
}

@keyframes oscillate-diagonal-tl-bl {
    0%{
        transform: translate(0px, 0px);
    }
    100%{
        transform: translate(-5px, 5px);
    }
}