.winner-all-elements-container {

}

.winner-card-container {
    font-family: 'Russo One', sans-serif;
    min-width: 20rem;
    text-align: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.winner-fish {
    background-color: black;
    color: white;
    height: fit-content;
    font-size: 1rem;
    padding: 10px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotating-stars .rotating-star {
    animation: spin 2s infinite alternate ease-in-out;
}

.winner-fish:before {
    content: "";
    background: url('./img/star1.svg');
    background-size: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    display: inline-block;
}

.winner-fish:after {
    content: "";
    background: url('./img/star1.svg');
    background-size: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
    display: inline-block;
}

.winner-background-rain {
    top: -10rem;
    position: absolute;
    width: 20rem;
    height: auto;
    overflow: hidden;

    /* https://makandracards.com/makandra/475887-letting-a-dom-element-fade-into-transparency */
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 50%, transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, black 50%, transparent 100%);

}

@keyframes spin {
    from { rotate: 0deg; padding-bottom: "3px" }
    to { rotate: 360deg; padding-bottom: "0px" }
  }