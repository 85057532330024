.cartoonDialogBox {
    background: white;
    color: black;
    box-shadow: 0 3px 0px 0px black;
    border-radius: 26px;
    border: 2px solid black;
    padding: 5px 19px;
    width: fit-content;
    white-space: nowrap;
    user-select: none;
}