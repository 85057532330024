.back-gradient {
  background: radial-gradient(
    82.62% 102.34% at 50% 91.54%,
    #5018f5 34.9%,
    #f8a0f2 100%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.dark-bg {
  background: #1b1b31;
}

.end-screen-container {
  /* margin: 10px; */
  padding: 10px;
  color: #fff;
  text-align: left;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: hidden;

  .player-tiles {
    display: flex;
    // flex-direction: column;
    // justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10rem;
    padding-bottom: 10rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .player-tile {
      position: relative;
      display: block;
      background: url("./img/platform.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center bottom;
      width: 25rem;
      height: 20rem;
      margin-right: -6rem;

      .player-name {
        position: absolute;
        bottom: 0;
        width: calc(100% - 8rem);
        text-align: center;
        font-size: 2rem;
        text-shadow: -0.1rem -0.1rem 0 #000, 0.1rem -0.1rem 0 #000,
          -0.1rem 0.1rem 0 #000, 0.1rem 0.1rem 0 #000;
      }

      .player-avatar {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 60%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 1;
      }

      .player-score {
        font-family: "Russo One";
        font-size: 3.5rem;
        line-height: 3rem;
        color: #ffffff;
        /* border: 2px solid #000000; */
        text-shadow: 4px -3px 0px #000000;
        position: absolute;
        bottom: 3.4rem;
        left: 2rem;
        -webkit-text-stroke: 0.2rem black;
      }

      .player-shadow {
        display: block;
        position: absolute;
        bottom: 10%;
        left: 0;
        width: 100%;
        height: 60%;
        background-size: 60%;
        background-repeat: no-repeat;
        background-position: center;
      }

      .player-avatar.winner {
        animation: jump 0.3s linear alternate infinite;
      }
    }
  }
}

@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -5%);
  }
  100% {
    transform: translate(0, -20%);
  }
}
