.autoplay-gradient {
  background: radial-gradient(
    82.62% 102.34% at 50% 91.54%,
    #5018f5 34.9%,
    #f8a0f2 100%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.autoplay-content-container {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  /* width: 60rem;
  min-width: 800px; */
  z-index: 2;
  transform: translate(-50%, -50%);
}

.autoplay-content-container p {
  font-size: 1.5rem;
  margin: 0;
}

.autoplay-content-container .game-info-container {
  display: flex;
}

.autoplay-content-container.instructions-mode {
  right: 2rem;
  left: inherit;
  transform: translate(0px, 0px);
  top: inherit;
  bottom: 2rem;
  background: #161c29;
  border-radius: 4.5rem;
  padding: 4rem;
}

.autoplay-content-container.instructions-mode .autoplay-buttons-container {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* display: block; */
}

.autoplay-content-container .game-instructions {
  height: 10rem;
  max-width: 20rem;
}

.autoplay-content-container.instructions-mode .autoplay-button {
  background: transparent;
  padding: 0;
}

.game-info-text-container {
  text-align: left;
  margin-left: 2rem;
}

.game-info-image-container {
  width: 25rem;
  height: 17rem;
  background-size: cover;
  background-position: center center;
  border-radius: 1.5rem;
}

.game-info-text-container h1 {
  margin: 0px;
  font-size: 3.5rem;
  font-weight: 400;
}

.game-info-container p {
  max-width: 25rem;
}

.autoplay-buttons-container {
  margin-top: 2rem;
}

.autoplay-button {
  background: #000;
  display: inline-block;
  padding: 1rem 2rem;
  border-radius: 2rem;
  font-size: 1rem;
  /* width: "100%"; */
  width: fit-content;
}

.autoplay-skip-button {
  white-space: "nowrap";
  margin-top: 20px;
  color: white;
  background: red;
  background: linear-gradient(to left, black 50%, rgb(84, 84, 84) 50%) right;
  background-size: 200%;
  background-position: right;
  /* transition: 5s ease-out; */
  animation: makeItfadeIn 20s linear forwards;
}

@keyframes makeItfadeIn {
  100% {
    background-position: left;
  }
}

.autoplay-button .autoplay-countdown {
  font-weight: bold;
  font-size: 4rem;
  margin-left: 1rem;
  line-height: 3rem;
  vertical-align: middle;
}

.autoplay-content-container.slide-in {
  animation: slide-left 1s ease-in;
}

@keyframes slide-left {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-down-transform {
  from {
    transform: translateY(-200%);
    opacity: 1;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes slide-up-slight {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  10% {
    transform: translateY(0%);
    opacity: 1;
  }

  90% {
    transform: translateY(0%);
    opacity: 1;
  }

  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

@keyframes slide-up-slight2 {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }

  55% {
    transform: translateY(50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}