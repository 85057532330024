#tsparticles {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.team-select-container {
}
.team-select-container .avatar-holder {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("./img/plus.png");
  background-size: 23px;
  background-position: center;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 10px;
  border: 3px solid transparent;
}

.team-select-container .team-container {
  display: flex;
  justify-content: center;

  border: 4px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 7px 0px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  padding: 30px 0px;
  flex-grow: 1;
  margin: 30px;
}

.team-select-container .team-containers {
  display: flex;
}

.team-select-container .team-container.orange {
  background: #ff7144;
}

.team-select-container .team-container.green {
  background: #d3ff9c;
}

.team-select-container .avatars-bench {
  display: flex;
  justify-content: center;
}

.team-select-container .button-start {
  background: #ffffff;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 7px 0px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  font-size: 30px;
  padding: 10px 40px;
  font-weight: bold;
}

.team-select-container .lilita-loader {
  font: 12px "Lilita One";
  opacity: 0;
}
