#overlay {
  position: absolute;
  opacity: 0;
  top: 0;
  bottom: 0;
  background: #000;
  width: 100%;
  height: 100vh;
  z-index: -1;
  visibility: hidden;
  transition: all 0.2s ease;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
#popup {
  position: absolute;
  bottom: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  color: #fff;
  width: 80vw;
  margin-left: -40vw;
  margin-top: -40vw;
  z-index: -1;
  visibility: hidden;
  display: none;
}
#popupclose {
  float: right;
  padding: 10px;
  cursor: pointer;
  font-size: 25px;
}
.popupcontent {
  text-align: center;
  padding: 30px 20px;
}
#button {
  cursor: pointer;
}

#popup[data-pop="slide-up"] {
  bottom: -1000px;
  transition: all 0.2s ease-in-out;
}
#popup[data-pop="slide-up"].show {
  visibility: visible;
  z-index: 200;
  opacity: 1;
  bottom: 50%;
  display: block;
  transform: translateY(50%);
}

.modal-container.modal-container.fullscreen #popup[data-pop="slide-up"].show {
  transform: none;
}
#popup[data-pop="slide-up"].show ~ #overlay {
  opacity: 1;
  visibility: visible;
  z-index: 100;
  opacity: 0.7;
}

.modal-container.fullscreen #popup[data-pop="slide-up"].show {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  width: auto;
}
