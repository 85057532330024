.show-points-container {
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  height: 4.5rem;
  width: fit-content;
  align-items: center;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  opacity: 0;
  animation: 1.5s slide-up-slight forwards;
  z-index: 999;
}

.controls-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column-reverse;
  padding-bottom: 100px;
  align-items: center;
}

.controls-container > button {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  font-size: 30px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffffa1;
  text-transform: uppercase;
  border-width: 5px;
}

.player-rocket-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 1.3rem;
}

.launch-pad .launch-bar {
  background-color: #5bc0de;
  width: 70% !important;
  position: absolute;
  bottom: 0;
  transition: height 0.5s;
  left: 15%;
  box-shadow: inset 4px 4px 8px rgb(255 255 255 / 25%);
  border-radius: 5px;
}

.rockets-container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column-reverse;
  align-items: center;
  transition: bottom 4s;
}

.rockets-container .rockets {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.rocket-ship {
  position: relative;
  /* transform: translate(0px, 3rem); */
  /* transition: transform 2s ease-in; */
}

.mini-card {
  min-height: 40px;
  height: 40px;
  width: 30px;
  border-radius: 8px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mini-card img {
  height: 14px;
  width: auto;
  /* background-image: url("./img/tick.png"); */
  /* background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px center; */
}

/* https://stackoverflow.com/questions/22082684/multiple-simultaneous-css3-transform-transitions-at-varied-speeds */

.flippable-card-wrapper-1 {
  transform: rotateY(90deg);
  animation: flip-appear 0.75s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

.flippable-card-wrapper-2 {
  transform: scale(1.15);
  animation: scale-appear 0.75s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

@keyframes flip-appear {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes scale-appear {
  from {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

.scalable-card-wrapper-1 {
  transform: scale(1);
  animation: scale-active 0.5s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

.scalable-card-wrapper-2 {
  transform: positionY(0%);
  animation: position-active 0.25s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

@keyframes flip-appear {
  from {
    transform: rotateY(90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

@keyframes scale-appear {
  from {
    transform: scale(1.15);
  }
  to {
    transform: scale(1);
  }
}

@keyframes scale-active {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.25);
  }
}

@keyframes position-active {
  from {
    transform: positionY(0%);
  }
  to {
    transform: positionY(-15%);
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}

.composed-avatar .avatar-holder {
  width: 5rem;
  height: 5rem;
  background-color: #ffffff;
  border: 0.3rem solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 2.5rem;
  left: 5.2rem;
}

.composed-avatar h2 {
  font-weight: bold;
  /* -webkit-text-stroke: 1px black; */
  color: white;
  text-shadow:
      /* 3px 3px 0 #000, */ -1.5px -1.5px 0 #000,
    1.5px -1.5px 0 #000, -1.5px 1.5px 0 #000, 1.5px 1.5px 0 #000;
}

.centered-container {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.centered-container .card {
  opacity: 0;
  -webkit-animation: fly-in 1s forwards; /* for less modern browsers */
  animation: fly-in 1s forwards;
}

@keyframes fly-in {
  0% {
    transform-origin: top;
    transform: perspective(1000px) rotateX(-40deg) translateY(25%)
      rotateY(360deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0) translateY(0) rotateY(0deg);
  }
}

@keyframes rotate-left {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: -21deg;
  }
}

@keyframes rotate-right {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 21deg;
  }
}

.space-container {
  color: #fff;
  opacity: 1;
  background-color: #29395690;
  border: 0.3rem solid #000000;
  border-bottom: 0.8rem solid #000000;
  border-radius: 2rem;
}

.space-container.space-btn {
  border-bottom: 1rem solid #000000;
  border-radius: 2.4rem;
  transition: 0.1s ease-in;
}

.space-container.space-btn:active {
  border-bottom: 0.8rem solid #000000;
  background-color: #19253b;
  transition: 0.05s ease-out;
  transform: scale(0.97);
}

.dialog-container {
  min-width: 300px;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 5.5rem 3rem;
  font-weight: normal;
}

.dialog-container h1 {
  font-size: 3rem;
  font-weight: normal;
}

.dialog-container h2 {
  font-size: 1.5rem;
  font-weight: normal;
}

@keyframes bgslide {
  to {
    background-position: 0 -200%;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.background-game-container .star {
  background: url("./img/star.png") no-repeat;
  background-size: contain;
  width: 1rem;
  height: 1rem;
  position: absolute;
}

.background-game-container .star.topleft {
  top: 10rem;
  left: 10rem;
}

.background-game-container .star.topright {
  top: 13rem;
  right: 5rem;
  transform: scale(1.3);
}

.background-game-container .star.bottomleft {
  bottom: 13rem;
  left: 5rem;
  transform: scale(1.1);
}

.background-game-container .star.bottomright {
  bottom: 9rem;
  right: 15rem;
  transform: scale(1.4);
}

/* Player indicator styles */
.player-indicator-score {
  position: absolute;
  top: -20rem;
  z-index: 996;
  opacity: 0;
  transform: translateY(3rem);
  animation: 1.4s fadeInOut 0.2s forwards, 1s slideInLong 0.2s forwards;
  animation-timing-function: cubic-bezier(0.01, 0.6, 0.145, 1);
}

@keyframes slideInLong {
  from {
    transform: translateY(3rem);
  }
  to {
    transform: translateY(0);
  }
}
