.countdown-container {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #2ece5b;
  font-family: "Lilita One";
  opacity: 0.5;
  font-size: 12rem;
  transform: translate(-50%, -50%);
}

.viewer-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100vh;
  width: 100vw;
}

.fb-counter-container {
  position: absolute;
  left: 50%;
  top: 40%;
  /* color: #2ECE5B; */
  font-family: "Lilita One";
  opacity: 1;
  font-size: 6rem;
  transform: translate(-50%, -50%);
}

.player-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0.7rem;
  padding-bottom: 2rem;
  font-family: "Lilita One";
  font-style: normal;

  /* https://stackoverflow.com/questions/12150377/css3-transform-scale-stick-to-bottom */
  -webkit-transform-origin : 50% 100%;
  -moz-transform-origin : 50% 100%;
  -o-transform-origin : 50% 100%;
  -ms-transform-origin : 50% 100%;
  transform-origin : 50% 100%;

  color: white;
  /* -webkit-text-stroke: 0.2rem black; */
    text-shadow:
    -2px -2px 0 #000,
     0   -2px 0 #000,
     2px -2px 0 #000,
     2px  0   0 #000,
     2px  2px 0 #000,
     0    2px 0 #000,
    -2px  2px 0 #000,
    -2px  0   0 #000;
  -webkit-text-fill-color: white;
}

.viewer-container .player-container .avatar-holder {
  height: 10rem;
  width: 10rem;
  border-width: 1.6rem;
}

.viewer-container .player-container .name {
  font-size: 1.4rem;
}

.viewer-container .player-container .score {
  font-weight: 400;
  font-size: 3.8rem;
  padding: 1rem 0rem 2rem;
}

.winner-container {
  position: absolute;
  left: 50%;
  top: 40%;
  font-family: "Lilita One";
  color: #ffffff;
  font-size: 12rem;
  transform: translate(-50%, -50%);
}

.winner-container .avatar-holder, .player-container .avatar-holder {
  /* margin: 1rem; */
}

.circle {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: inline-block;
}

.control-container {
  background: linear-gradient(
    215.23deg,
    #d5faff 8.03%,
    rgba(213, 250, 255, 0) 131.56%
  );
}

.control-canvas {
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  display: flex;
  padding: 50px;
}

.control-canvas > div {
  transition: all 0.1s linear;
}

.bucket-container {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 3rem;
  /* left: 50%;
  transform: translate(-50%); */
}

.bucket-score {
  position: absolute;
  right: -60px;
  font-size: 40px;
  top: -40px;
  opacity: 1;
  animation: fadeToTop 0.5s ease-in forwards;
}

@keyframes fadeToTop {
  to {
    margin-top: -50px;
    opacity: 0;
  }
}

@keyframes topToBottom {
  0% {
    top: -100px;
  }
  100% {
    top: calc(200px + 100vh);
  }
}
