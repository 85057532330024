:root {
  --bg-surface-base: #121316;
  --bg-surface-light2: #272729;
}

body {
  background: #5f33ff;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.main-menu {
  text-align: center;
  height: 100vh;
  overflow-x: hidden;
}

.main-menu .button-black {
  vertical-align: bottom;
  width: 60vw;
  max-width: 500px;
}

.main-menu .button-black.create-disabled {
  background: #000000aa;
  position: relative;
}

.main-menu .lock-icon {
  background-image: url("../common/imgs/lock.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -15px;
  left: -15px;
}

.logo {
  background-image: url("../common/imgs/logo-dark.png");
  width: 164px;
  height: 51px;
  display: inline-block;
  margin-top: 90px;
  margin-bottom: calc(60vh - 50px);
  background-size: contain;
  background-repeat: no-repeat;
}

.main-menu-web .top-bar {
  margin-top: 10px;
}

.main-menu-web .top-bar .logo {
  margin: 0;
  display: block;
}

.main-menu-web .hero h1 {
  font-size: 55px;
  text-align: left;
  line-height: 110%;
  letter-spacing: -0.01em;
}
.main-menu-web .hero h3 {
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: -0.01em;
}

.hero {
  margin-top: 100px;
}

.hero .input-group {
  display: flex;
}

.hero .input-group button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin: 0;
  box-shadow: none;
}

.hero .input-group input {
  flex: 1;
  font-size: 21px;
  padding: 20px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 0px;
}

.initializing-banner {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(
    to bottom,
    var(--bg-surface-light2) 25%,
    var(--bg-surface-base) 75%
  );
}
