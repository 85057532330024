/*
Reference:
- ~https://codepen.io/rauldronca/pen/PzMgzp~
- https://codepen.io/nuzair/details/MrJWrB
*/

/*
  ==========================
  3D BUTTON 2
  ==========================
*/

.touch-twobutton-zone.rocketfuel-launch-button {
	// position: absolute;
	text-transform: uppercase;
}

.touch-twobutton-zone.rocketfuel-launch-button .button1 {
	text-transform: uppercase;
	// position: relative;
	background: #ecd300;
	// background: -webkit-radial-gradient( hsl(54, 100%, 50%), hsl(54, 100%, 40%) );
	// background: -o-radial-gradient( hsl(54, 100%, 50%), hsl(54, 100%, 40%) );
	// background: -moz-radial-gradient( hsl(54, 100%, 50%), hsl(54, 100%, 40%) );
	// background: radial-gradient( hsl(54, 100%, 50%), hsl(54, 100%, 40%) );
	font-size: 1.4rem;
	text-shadow: 0 -1px 0 #00000036;
	color: #fff;
	border: solid 1px hsl(54, 100%, 20%);
	border-radius: 100%;
	height: 70vw;
	width: 70vw;
	margin: 15vw;
	// margin: 30vw;
	z-index: 4;
	outline: none;
	box-shadow: inset 0 1px 0 hsl(54, 100%, 50%),
	  0 2px 0 hsl(54, 100%, 20%),
	  0 3px 0 hsl(54, 100%, 18%),
	  0 4px 0 hsl(54, 100%, 16%),
	  0 5px 0 hsl(54, 100%, 14%),
	  0 6px 0 hsl(54, 100%, 12%),
	  0 7px 0 hsl(54, 100%, 10%),
	  0 8px 0 hsl(54, 100%, 8%),
	  0 9px 0 hsl(54, 100%, 6%);
  }
  .touch-twobutton-zone.rocketfuel-launch-button.disabled {
	pointer-events: none;
	z-index: -1;
	text-transform: uppercase;
  }

  .touch-twobutton-zone.rocketfuel-launch-button .button1 .circle {
	border: 0.2rem solid #00000050 !important;
	height: 100% !important;
	width: 100% !important;

	color: black;

	background-image: url("./img/fuelicon.png");
	background-repeat: no-repeat;
	background-position: center;
	background-position-y: 70%;
	background-size: 30px;


  }

	// .touch-twobutton-zone.rocketfuel-launch-button .button1:hover {
	//   background: -webkit-radial-gradient( hsl(54, 100%, 45%), hsl(54, 100%, 35%) );
	// background: -o-radial-gradient( hsl(54, 100%, 45%), hsl(54, 100%, 35%) );
	// background: -moz-radial-gradient( hsl(54, 100%, 45%), hsl(54, 100%, 35%) );
	// background: radial-gradient( hsl(54, 100%, 45%), hsl(54, 100%, 35%) );
	// }
	.touch-twobutton-zone.rocketfuel-launch-button .button1:active, 
	.touch-twobutton-zone.rocketfuel-launch-button .button1:hover
	{
	  background: webkit-radial-gradient( hsl(54, 100%, 43%), hsl(54, 100%, 33%) );
	background: -o-radial-gradient( hsl(54, 100%, 43%), hsl(54, 100%, 33%) );
	background: -moz-radial-gradient( hsl(54, 100%, 43%), hsl(54, 100%, 33%) );
	background: radial-gradient( hsl(54, 100%, 43%), hsl(54, 100%, 33%) );
	  transform: translateY(2px);
	  box-shadow: inset 0 1px 0 hsl(54, 100%, 50%),
	  0 2px 0 hsl(54, 100%, 20%),
	  0 3px 0 hsl(54, 100%, 18%),
	  0 4px 0 hsl(54, 100%, 16%),
	  0 5px 0 hsl(54, 100%, 14%),
	  0 6px 0 hsl(54, 100%, 12%),
	  0 7px 0 hsl(54, 100%, 10%);
	}
  