.fullscreen-fixed {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 999;
}

.animated-gradient {
    background: radial-gradient(circle farthest-side at bottom right, #ff0000, #ffa500, #ffff00, #00ff00, #0000ff, #ee82ee);
    background-size: 1000% 500%;
    -webkit-animation: gradientbg 15s ease infinite;
    -moz-animation: gradientbg 15s ease infinite;
    animation-name: gradientbg;
    animation-duration: 15s;
    animation-timing-function: ease-in-out;
    /* animation-delay: ; */
    animation-iteration-count: infinite;
    /* animation-direction: ; */
    /* animation-fill-mode: ; */
    mix-blend-mode: color-burn;
  }
    
      
   @-webkit-keyframes gradientbg {
      0%{background-position:0% 7%}
      50%{background-position:100% 94%}
      100%{background-position:0% 7%}
  }
  @-moz-keyframes gradientbg {
      0%{background-position:0% 7%}
      50%{background-position:100% 94%}
      100%{background-position:0% 7%}
  }
  @keyframes gradientbg {
      0%{background-position:0% 7%}
      50%{background-position:100% 94%}
      100%{background-position:0% 7%}
  }