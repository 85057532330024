.player-wrapper {
    width: auto; /* Reset width */
    height: auto; /* Reset height */
  }
  .react-player {
    padding-top: 56.25%; /* Percentage ratio for 16:9 */
    position: relative; /* Set to relative */
  }
  
  .react-player > div {
    top: 0;
    position: absolute; /* Scaling will occur since parent is relative now */
  }

  .start-screen-dialog-container {
    margin-top: 12rem;
    padding: 0rem 3rem;
    opacity: 0;
    animation: slide-up-slight2 1.8s ease-in-out forwards;
  }