.dogfight-player-scores {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
}

.dogfight-player-scores .player-score-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 2rem;
  border-radius: 2rem;
  padding: 0.2rem;
}

.dogfight-player-scores .player-score-container > span {
  color: #ffffff;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  margin: 0rem 0.7rem;
}

.dogfight-player-scores .avatar-holder {
  background-color: rgba(255, 255, 255, 0.8);
  background-size: 23px;
  background-position: center;
  background-repeat: no-repeat;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin: 0.1rem;
  border: 0.15rem solid transparent;
}

.touch-joystick-zone.dogfight .dpad .circle {
  border-color: #000000;
  color: #000000;
}

.touch-joystick-zone.dogfight .button1 {
  border-color: #000000;
  background-image: url("./img/fireicon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-position-y: center;
  background-size: 9vw;
}

.hud-dogfight .hud-avatar .avatar {
  background-size: 120%;
  background-position: -1.5rem;
  border-radius: 50%;
}

.hud-dogfight .hud-player-score {
  padding: 2rem 3rem;
  padding-left: 8rem;
  border-radius: 8rem;
  color: #ffffff;
  background: url("./img/coin-icon.png") no-repeat 3rem center;
  background-color: #ff7f56;
  background-size: 4rem;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: 4rem;
  margin: 0rem 0.7rem;
  -webkit-text-stroke: 3px black;
}
