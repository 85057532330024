@keyframes horizontalSineWave {
  to {
    transform: translatey(100px);
  }
}

@keyframes verticalSineWave {
  to {
    transform: translatex(100px);
  }
}

@keyframes fullScreenTranslateX {
  to {
    transform: translateX(1100%);
  }
}

@keyframes fullScreenTranslateY {
  to {
    transform: translateY(-125vh);
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}