
.smoke-layer {
    position: absolute;
    width: 400%;
    height: 400%;
    bottom: -250%;
    left: 50%;
    background-color: #e8e6ef;
    transform: translateX(-50%) translateY(1%) scale(0.25);
    z-index: -1;
  }
  
  .smoke-layer-bubble {
    position: absolute;
    background-color: #e8e6ef;
    border-radius: 50%;
    animation: smoke-layer-animation infinite 7s alternate ease-in-out;
  }
  
  .smoke-layer-bubble:nth-child(1){
     left: 1%;
     top: -22%;
     width: 20%;
     height: 20%;
     animation-duration: 5s;
  }
  .smoke-layer-bubble:nth-child(2){
     right: 1%;
     top: -22%;
     width: 20%;
     height: 20%;
     animation-duration: 5s;
  }
  .smoke-layer-bubble:nth-child(3){
    left: -6%;
      top: -28%;
      width: 15%;
      height: 15%;
      animation-duration: 3s;
  }
  .smoke-layer-bubble:nth-child(4){
    right: -6%;
      top: -28%;
      width: 15%;
      height: 15%;
      animation-duration: 3s;
  }
  .smoke-layer-bubble:nth-child(5){
    left: 12%;
      top: -11%;
      width: 20%;
      height: 20%;
      animation-duration: 6s;
  }
  .smoke-layer-bubble:nth-child(6){
    right: 12%;
      top: -11%;
      width: 20%;
      height: 20%;
      animation-duration: 6s;
  }
  .smoke-layer-bubble:nth-child(7){
    left: 28%;
      top: -7%;
      width: 14%;
      height: 14%;
      animation-duration: 3s;
  }
  .smoke-layer-bubble:nth-child(8){
    right: 28%;
      top: -7%;
      width: 14%;
      height: 14%;
      animation-duration: 3s;
  }
  .smoke-layer-bubble:nth-child(9){
    left: 35%;
      top: -6%;
      width: 19%;
      height: 19%;
      animation-duration: 5s;
  }
  .smoke-layer-bubble:nth-child(10){
    right: 35%;
      top: -6%;
      width: 19%;
      height: 19%;
      animation-duration: 5s;
  }
  .smoke-layer-bubble:nth-child(11){
      left: -9%;
      top: -19%;
      width: 30%;
      height: 30%;
      animation: none;
  }
  
  .smoke-layer-bubble:nth-child(12){
      right: -9%;
      top: -19%;
      width: 30%;
      height: 30%;
      animation: none;
  }
  
  
  @keyframes smoke-layer-animation {
      0% {
        transform: scale(1.2);
      }
      50% {
         transform: scale(0.8);
      }
      100% {
        transform: scale(1.2);
      }
    }