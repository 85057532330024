.card-spread {
    width: 350px;
    height: 250px;
    position: relative;
  }
   
  .card-spread .card {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: top 0.3s ease, left 0.3s ease;
    -moz-transition: top 0.3s ease, left 0.3s ease;
    -o-transition: top 0.3s ease, left 0.3s ease;
    -ms-transition: top 0.3s ease, left 0.3s ease;
    transition: top 0.3s ease, left 0.3s ease;
  }

  .spread1 {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
   
  .spread2 {
    left: 30px;
    top: 0px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
   
  .spread3 {
    left: 60px;
    top: 5px;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
  }
   
  .spread4 {
    left: 80px;
    top: 10px;
    -webkit-transform: rotate(20deg);
    -moz-transform: rotate(20deg);
    -o-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }