.beta-tag-bubble {
    overflow: hidden;
    position: absolute;
    bottom: 50%;
    /* right: 50%; */
    border-radius: 3.2rem;
    width: auto;
    height: 3.2rem;
    background: rgba(0, 0, 0, 0.80);
    padding: 1rem;
    color: white;
    -webkit-transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .unexpanded-text {
    white-space: nowrap;
    margin-left: 0rem;
    font-size: 0 !important;
    line-height: 1rem;
    color: black;
    text-decoration: none;
    opacity: 0;
    -webkit-transition-delay: 2s;

    /* Safari */
    transition-delay: 2s;
    -webkit-transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .beta-tag-bubble .expanded {
    overflow: visible;
    border-radius: 3.2rem;
    padding: 1rem;

    height: 30px;

    color: white;
    -webkit-transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4;
    transition: all 0.55s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4;
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  .expanded-text {
    opacity: 1;
    -webkit-transition-delay: 2s;
    font-size: 1rem !important;
    line-height: 1rem;
    margin-left: 0.4rem;


    /* Safari */
    color: white;
    transition-delay: 2s;
    -webkit-transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: opacity 1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  /* For containers of betatag... so we don't have to 
  rewrite everywhere */
.beta-tag-container {
  position: fixed;
  top: 3rem;
  left: 50%;
  z-index: 999;
  height: 30px;
  animation: slide-down-transform 1s 2s;
}

.beta-tag-container.right{
  right: 3%;
  left: auto;
  /* left: 0; */
  animation: slide-left 1s 2s;
}

.beta-tag-container.left{
  left: 3%;
  right: auto;
  animation: slide-right 1s 2s;
}