.games-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: -100px;
  background: #0e0391;
  z-index: 2; /* above touch joystick */
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  padding-top: env(safe-area-inset-top);
  padding-bottom: 200px;
}

.button-menu-container {
  position: absolute;
  right: 0;
  top: env(safe-area-inset-top);
}
.button-menu {
  font-family: "Russo One", sans-serif;
  border: none;
  color: white;
  padding: 10px 18px;
  font-size: 18px;
  line-height: 22px;
  margin: 5px;
  background: rgba(0, 0, 0, 1);
  box-shadow: 0px 14px 20px rgb(0 0 0 / 38%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 20px;
  border-top-right-radius: 0px;
}

.button-menu.purple {
  background: #bc76ff;
}
.game-code {
  color: #fff;
  margin: 20px;
  display: inline-block;
}

#game-menu-preview {
  /* width: calc(100vw - 23px); */
  border: 3px solid #ffac92;
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
  text-align: center;
  box-shadow: 0px 22px 63px -20px #ffac92;
  height: calc(100vw / 1.6);
  background-size: cover;
  background-position: center;
}

#game-menu-preview .no-preview {
  color: #fff;
  font-size: 14px;
  padding: 130px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#game-menu-preview.on {
  border-color: #6bdcff;
  box-shadow: 0px 22px 63px -20px #6bdcff;
}

#game-menu-preview canvas {
  width: 100%;
  display: inherit;
}

#game-menu-preview span {
  background: #ffac92;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 2px 30px;
  font-size: 13px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#game-menu-preview.on span {
  background: #6bdcff;
}

.dummy-rect {
  background: gray;
  height: 100px;
  width: 100%;
  display: block;
  border-radius: 10px;
  margin-top: 10px;
}

.ingame-topbar {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  padding-top: env(safe-area-inset-top);
}

.ingame-topbar.gradient {
  background: linear-gradient(
    180deg,
    #161e37 11.3%,
    rgba(22, 30, 55, 0.609375) 67.28%,
    rgba(22, 30, 55, 0) 100%
  );
}

.ingame-topbar .logo-small {
  background-image: url("/src/common/imgs/logo-light.png");
  background-size: contain;
  display: block;
  width: 145px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  top: 8px;
}

.games-menu-container .topbar {
  display: flex;
  justify-content: space-between;
  padding: 1.7rem 2.2rem 0rem 2.2rem;
}

.games-menu-container .button-cast,
.games-menu-container .player-photo {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 48px;
  height: 48px;
  background-color: #000;
  border-radius: 50%;
}

.games-menu-container .player-username {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 800;
  /* Prefix required. Even Firefox only supports the -webkit- prefix */
  /* -webkit-text-stroke-width: 0.01rem;
  -webkit-text-stroke-color: black; */

  text-shadow: -2px 1px 0 #000, 2px 1px 0 #000, 2px -1px 0 #000;
}

.games-menu-container .player-photo {
  background-size: contain;
  border: 3px solid #d4ff9c;
}
.games-menu-container .player-photo.hidden {
  visibility: hidden;
}
.games-menu-container .button-cast {
  background-image: url("./img//cast-off.png");
}

.games-menu-container .button-cast.on {
  background-image: url("./img//cast-on.png");
}

.games-menu-container .topbar .logo-small {
  background-image: url("/src/common/imgs/logo-early-access.png");
  background-size: contain;
  display: block;
  width: 101px;
  height: 48px;
  background-position: center;
  background-repeat: no-repeat;
}

.cast-menu {
  display: inline-block;
  height: 90vh;
  overflow: auto;
  padding-bottom: 100px;
  width: 100%;
}

.cast-menu h3 {
  font-size: 11px;
  margin-bottom: 70px;
}

.cast-menu b {
  float: left;
  font-size: 14px;
  clear: both;
}

.cast-menu > b {
  margin: 40px 0px;
}

.cast-menu span {
  float: left;
  font-size: 14px;
  color: #6bdcff;
  clear: both;
}

.cast-menu span.airplay {
  background-image: url("./img/airplay.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 0px center;
  padding-left: 35px;
}

.cast-menu .instructions {
  display: block;
  float: left;
  text-align: left;
  font-size: 13px;
  line-height: 1.7;
  padding-right: 12px;
  clear: both;
}

.room-code-box {
  position: relative;
  padding: 5px 18px;
  box-sizing: border-box;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  border: solid 3px #ff67e7;
  border-radius: 10px;
  font-size: 16px;
  margin-top: 5px;
  top: 17px;
  letter-spacing: 3px;
  /* border-image-slice: 1; */
  /* border-image-source: linear-gradient(to bottom, #FF67E7, #FDF5AF); */
}

.room-code-box.casting {
  font-size: 25px;
  position: absolute;
  right: 12px;
  top: 5px;
}

.games-menu-container .games-list {
  padding: 10px;
  background: #1805f5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.games-menu-container .games-list .playlist-header {
  display: flex;
  gap: 1rem;
  margin-bottom: 25px;
}

.games-menu-container .games-list .playlist-header h3,
.games-menu-container .games-list .playlist-header span {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 26px;
  /* or 108% */
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-bottom: 5px;
  margin-top: 5px;
}

.games-menu-container .games-list .playlist-header span {
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
}

.games-menu-container .games-list .playlist-header img {
  width: 112px;
  height: auto;
}

.games-menu-container .menu-and-logo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
}

.games-menu-container .menu-and-logo > * {
  align-self: center;
}

.games-menu-container .menu-and-logo .right-container {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-end;
}

.games-menu-container .mood-container.disabled {
  /* pointer-events: none; */
  opacity: 0.3;
}

.games-menu-container .mood-container h3 {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 14px;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin: 28px 10px 18px;
}

.games-menu-container .game-tile {
  background-color: transparent;
  background: url("./img/play.png") no-repeat left center;
  background-size: contain;
  border: 0px;
  text-align: left;
  padding-left: 50px;
  margin: 10px 0px;
  color: #ffffff;
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
}

.games-menu-container .game-tile.disabled {
  background-image: none;
  padding-left: 0;
}

.games-menu-container .game-tile .game-info .subtitle {
  font-weight: 100;
  font-family: "Open Sans";
}

.games-menu-container .game-tile .game-info {
  display: flex;
  flex-direction: column;
}

.games-menu-container .game-tile .game-info .title {
  font-style: normal;
  font-size: 16px;
  line-height: 19px;
}

.games-menu-container .mood-list {
  max-width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}

/* .games-menu-container .mood-list img {
  width: calc(100vw / 2 - 20px);
  height: auto;
  border-radius: 8px;
} */

.games-menu-container .mood-list .playlist-container {
  margin-left: 10px;
  border-radius: 8px;
  position: relative;
  z-index: 2;
  display: inline-block;
  cursor: pointer;
  /* width: 300px; */
  min-height: 50px;
  min-width: 20px;
  width: 42vw;
  height: auto;
}

.games-menu-container .mood-list .playlist-container .playlist-info-overlay {
  z-index: 3;
  position: absolute;
  bottom: 25%;
  max-width: 70%;
  background: black;
  color: white;
  font-size: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.games-menu-container .mood-list .playlist-container .playlist-locked-overlay {
  z-index: 4;
  position: absolute;
  background: #00000043;
  /* opacity: 0.4; */
  height: 100%;
  width: 100%;
  border-radius: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.games-menu-container
  .mood-list
  .playlist-container
  .playlist-locked-overlay
  img {
  /* z-index: 4; */
  /* position: absolute; */
  background: #00000043;
  opacity: 0.86;
  width: 40%;
  height: auto;
  /* border-radius: inherit; */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
}

.games-menu-container .roomcode-container {
  padding: 4.4rem 20px;
  margin: 10px;
  margin-bottom: 0;
  border: 2px solid #1805f5;
  border-radius: 20px;
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  position: relative;
}

.games-menu-container .roomcode-container.playing {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: 0px;
  margin-bottom: -40px;
  z-index: -1;
  border-color: #1805f5;
}

.games-menu-container .roomcode-container.playing .status {
  background-color: #1805f5;
}

.games-menu-container .roomcode-container .roomcode-title {
  font-size: 0.8rem;
}

.games-menu-container .code-play-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.games-menu-container .code-play-container .inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.games-menu-container .roomcode-container .roomcode {
  font-size: 3.2rem;
  line-height: auto;
  display: block;
}

.games-menu-container .roomcode-container .status {
  position: absolute;
  top: 0px;
  left: 0;
  font-size: 13px;
  background-color: #1805f5;
  padding: 2px 70px 2px 18px;
  border-bottom-right-radius: 40px;
  border-top-left-radius: 20px;
}

.games-menu-container .roomcode-container .icon-flag {
  width: 100px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  right: 0;
}

.games-menu-container .roomcode-container .btn-play {
  /* position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  right: 20px; */
  color: #000;
  background-color: #d3ff9c;
  background-image: url("./img/controller.png");
  background-repeat: no-repeat;
  background-size: 30px;
  background-position: 10px center;
  padding: 10px 20px 10px 50px;
  border-radius: 50px;
  cursor: pointer;
}

.games-menu-container .roomcode-container .roomcode span:first-child {
  border-color: #ffb0cc;
  color: #ffb0cc;
}

.games-menu-container .roomcode-container .roomcode span:nth-child(2) {
  border-color: #fff27a;
  color: #fff27a;
}

.games-menu-container .roomcode-container .roomcode span:nth-child(3) {
  border-color: #6bdcff;
  color: #6bdcff;
}

.games-menu-container .roomcode-container .roomcode span:nth-child(4) {
  border-color: #ff7f56;
  color: #ff7f56;
}

.games-menu-container .Collapsible {
  margin: 10px;
}

.games-menu-container .Collapsible__trigger {
  background-color: #1805f5;
  color: #fff;
  padding: 20px 20px;
  border-radius: 15px;
  background-image: url("./img/arrow-down.png");
  background-repeat: no-repeat;
  background-position: 94% 24px;
  background-size: 22px;
  width: 100%;
  display: block;
}

.games-menu-container .Collapsible .instruction-title {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 1.35rem;
  line-height: 1.4rem;
  padding: 0.2rem;
  padding-left: 40px;
  background-size: 22px;
  background-position: left center;
  background-repeat: no-repeat;
}

.games-menu-container .Collapsible .instruction-title.laptop {
  background-image: url("/src/common/imgs/cast-laptop.png");
}

.games-menu-container .Collapsible .instruction-title.airplay {
  background-image: url("/src/common/imgs/cast-airplay.png");
}

.games-menu-container .Collapsible .instruction-text {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* padding-bottom: 60px; */
  color: #ffffff;
}

.games-menu-container .Collapsible .icon-screenmirror {
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  width: 26px;
  height: 20px;
  background-image: url("/src/common/imgs/screen-mirror.png");
  display: inline-block;
  margin: 0px 8px;
}

.games-menu-container .Collapsible .instruction-text.highlighted {
  color: #6bdcff;
}
.games-menu-container .Collapsible .instruction-text li {
  margin-bottom: 20px;
}

.menu-welcome-popup .popupcontent {
  color: #000;
  background: #fff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 18px;
}

.menu-welcome-popup .popupcontent p {
  font-family: "Russo One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.menu-welcome-popup .popupcontent h3 {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.menu-welcome-popup .popupcontent .welcome-image {
  width: 64px;
}

.menu-welcome-popup h3 img {
  margin: -6px 0px;
  width: 24px;
}

.menu-welcome-popup .popupcontent .main-cta {
  display: inline-block;
  margin-top: 30px;
  color: #3905f3;
  font-size: 16px;
  padding: 20px;
  cursor: pointer;
}

.button-menu.back-to-menu-button {
  position: absolute;
  z-index: 1;
  top: -5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  transform: translateX(-50%);
}

.button-menu.start-button {
  position: fixed;
  z-index: 1;
  bottom: 6rem;
  right: 1rem;
  border-radius: 50px;
  padding: 15px 25px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.menu-bottombar {
  background-color: #000;
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
}

/* Create inverted border radius effect */
/*
  References:
  - How to create the shapes using psuedoelements: https://itnext.io/how-to-make-a-fancy-inverted-border-radius-in-css-5db048a53f95

  Research:
  - Z-index positioning of pseudoelements: https://stackoverflow.com/questions/3032856/is-it-possible-to-set-the-stacking-order-of-pseudo-elements-below-their-parent-e
*/
.menu-bottombar::before {
  content: "";
  position: absolute;
  z-index: -1;

  background-color: transparent;
  top: -50px;
  left: 0;
  height: 50px;
  width: 25px;
  border-bottom-left-radius: 25px;
  box-shadow: 0 25px 0 0 black; /* This is where the magic happens! */
}

.menu-bottombar::after {
  content: "";
  position: absolute;
  z-index: -1;

  background-color: transparent;
  top: -50px;
  right: 0;
  height: 50px;
  width: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 25px 0 0 black; /* This is where the magic happens! */
}

.menu-bottombar .btn {
  background-color: transparent;
  color: #fff;
  border-radius: 40px;
  cursor: pointer;
  display: block;
  height: 3rem;

  /* 
  Give every button a fixed width so
  they justify correctly, but allow them to overflow
  so that their internal stuff can be of any size 
  */
  width: 6rem;
  overflow: visible;

  background-size: 1.7rem;
  background-repeat: no-repeat;
  background-position: center;
  transition: 0.1s linear background-color;
}

.menu-bottombar .btn.active {
  background-color: #1805f5;
}

.menu-bottombar .btn.disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.menu-bottombar .btn.btn-host {
  background-image: url("./img/host-menu.png");
}

.menu-bottombar .btn.btn-controller {
  background-image: url("./img/controller-white.png");
  background-position: 50% 50%;
  background-size: 3rem;
}

.menu-bottombar .btn.btn-profile {
  display: flex;
  justify-content: center;
}

.menu-bottombar .avatar-holder {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 3rem;
  height: 3rem;
  background-color: #000;
  border-width: 4px;
  border-radius: 50%;
  border-style: solid;
}

.menu-control-buttons {
  /* margin-top: 10px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  min-height: 4rem;
  height: fit-content;
  background-color: #1805f5;
  margin: 2.7rem 10px 2.1rem 10px;
  padding: 0.3rem 0rem;
  border-radius: 0px 0px 20px 20px;
  padding-right: 2.2rem;
}

.menu-control-buttons button {
  margin-left: 2.2rem;
  width: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  text-align: center;
  vertical-align: center;
  align-items: center;
  cursor: pointer;
  align-self: center;
  border-radius: 11px;
  border: none;
  color: white;
  font: inherit;
  font-size: 8px;
  text-transform: uppercase;
  /* margin-right: 13px; */
  padding: 11px 24px;
  background-color: transparent;
}

.menu-control-buttons button:disabled {
  opacity: 0.2;
}

.menu-control-buttons button img {
  height: 20px;
  width: auto;
  margin-bottom: 7px;
}

.dev-indicator-fish {
  background: blue;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 4px;
  opacity: 0.5;
  font-size: 15px;
}
