.ingame-player-scores {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
}

.ingame-player-scores .player-score-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 2rem;
  border-radius: 2rem;
  padding: 0.2rem;
}

.ingame-player-scores .player-score-container > span {
  color: #ffffff;
  font-family: "Lilita One";
  font-style: normal;
  font-weight: 400;
  font-size: 3rem;
  margin: 0rem 0.7rem;
}

.ingame-player-scores .avatar-holder {
  background-color: rgba(255, 255, 255, 0.8);
  background-size: 23px;
  background-position: center;
  background-repeat: no-repeat;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  margin: 0.1rem;
  border: 0.15rem solid transparent;
}
